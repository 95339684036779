<script>

  import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
  export default {
    page: {
      title: "Login",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },

    data() {
      return {
        
   
        password: "",
         cpassword: "",
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
      };
    },
       props: ['email', 'code'],
    computed: {

    },
    methods: {
      async signinapi() {

       this.tryingToLogIn = true;
      
            await axios.post(process.env.VUE_APP_MAIN_URL + "/auth/reset/password", {email: this.email, password: this.password, password_confirmation: this.cpassword, token: this.code}).catch((error) => {

               this.tryingToLogIn = false;
                if(error.response.status == 420) {
                  Swal.fire("Error!", error.response.data.data, "error");
                }else {
                  Swal.fire("Error!", "Password must be greater or equal to six digits", "error");
                }
                throw error;

            }).then((response) => {
                 this.tryingToLogIn = false;
                if (response.data.status == true) {

                    Swal.fire("Success!", response.data.data, "success");


                }
                   this.$router.push({name: 'login'});

            })




       
      }
    },
  };
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="40" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Best bill payment in nigeria
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Reset Password !</h5>
                  <p class="text-muted">Reset Your Password</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                  <div>

                  </div>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" class="form-control" id="email" placeholder="Enter email" :value="email"  readonly />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      
                      <label class="form-label" for="password-input">New Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="password" v-model="password" class="form-control pe-5" placeholder="Enter password"
                          id="password-input" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>
<div class="mb-3">
                      
                      <label class="form-label" for="password-input">Confirm New Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="password" v-model="cpassword" class="form-control pe-5" placeholder="Enter password"
                          id="password-input" />
                        <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>
 

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit" @click="signinapi" :disabled="tryingToLogIn">
                      <div class="spinner-border spinner-border-sm" role="status" v-if="tryingToLogIn"> 
  <span class="sr-only">Loading...</span>
</div>
                    Change Password
                      </button>
                    </div>

                   
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

         
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Gopay. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Asap Softwares
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>